import Vue from 'vue'
import axios, { AxiosResponse, AxiosError } from 'axios'
import VueAxios from 'vue-axios'

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const axiosInstance = axios.create(config)

axiosInstance.interceptors.response.use((response: AxiosResponse) => {
  return response
}, (error: AxiosError) => {
  return Promise.reject(error)
})

Vue.use(VueAxios, axiosInstance)
