<template>
  <h1>C2MS Payment Middleware</h1>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class Home extends Vue {
}
</script>
