









import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class App extends Vue {
  version: { version: string; build: string; } = { version: '', build: '' }

  private async created () {
    this.version = await (await fetch('/version.json')).json()
  }
}
